<script setup lang="ts">
defineProps({
  collapsed: {
    type: Boolean,
    default: false,
  },
})

const showTimeTrackerTooltip = ref(false)

const timeTracker = useTimeTracker()
const latestTimeEntry = useLatestTimeEntry()
const { latestProject, latestTask } = storeToRefs(timeTracker)

const { data: latestProjectData, execute: fetchLatestProject } = useApiProjectGetById(latestTimeEntry.value.projectId || 0, { withTaskLists: true }, { immediate: false })
const { data: latestTaskData, execute: fetchLatestTask } = useApiTaskGetById(latestTimeEntry.value.taskId || 0, { withTaskList: true }, { immediate: false })

if (latestTimeEntry.value.projectId) {
  await fetchLatestProject()
  latestProject.value = latestProjectData.value?.data
}

if (latestTimeEntry.value.taskId) {
  await fetchLatestTask()
  latestTask.value = latestTaskData.value?.data
}
</script>

<template>
  <NTooltip
    :show="showTimeTrackerTooltip && collapsed && !!latestProject && !!latestTask"
    placement="right"
    @update:show="showTimeTrackerTooltip = $event"
  >
    <template #trigger>
      <aside class="box-border w-full rounded-2 px-1 py-2 has-[#tracker:hover]:bg-gray-300">
        <div v-show="!collapsed" class="px-3 font-semibold">
          <NuxtLink
            v-if="latestProject && latestTask"
            :to="{ name: 'projects-id-view', params: { id: latestProject.id, view: 'tasks' } }"
            class="block truncate text-sm text-gray-600 dark:text-gray-500 hover:underline"
          >
            {{ latestTask.name }}
          <!-- TODO: link to task/task edit modal -->
          </NuxtLink>
          <NuxtLink
            v-if="latestProject"
            :to="{ name: 'projects-id-view', params: { id: latestProject.id, view: 'tasks' } }"
            class="mb-2 mt-1 block truncate text-xs hover:underline"
          >
            {{ latestProject.name }}
          </NuxtLink>
        </div>
        <div
          id="tracker"
          class="group flex cursor-pointer items-center justify-between rounded-full p-2 hover:bg-gray-100"
          :class="{ 'flex-col-reverse': collapsed }"
          @click="timeTracker.openModal()"
        >
          <Stopwatch type="tracker" :collapsed="collapsed" />
          <NButton text type="default" :class="{ 'mb-2': collapsed, 'mr-1': !collapsed }">
            <template #icon>
              <Icon name="i-ic-baseline-open-in-full" class="group-hover:text-primary-hover" />
            </template>
          </NButton>
        </div>
      </aside>
    </template>
    <div class="font-semibold">
      {{ latestProject!.name }}
    </div>
    <div>{{ latestTask!.name }}</div>
  </NTooltip>
</template>

<style scoped>
</style>
