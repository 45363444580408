<script setup lang="ts">
import { breakpointsTailwind } from '@vueuse/core'
import { NLayout, NLayoutSider } from 'naive-ui'

const { isSmallerOrEqual } = useBreakpoints(breakpointsTailwind)
const siderCollapsed = ref(isSmallerOrEqual('sm'))

const messageApi = useMessage()
const { message } = storeToRefs(useMessageStore())

watch(message, (msg) => {
  if (msg) {
    messageApi.create(msg.message, msg.options)
  }
}, { immediate: true })
</script>

<template>
  <NLayout :has-sider="true" position="absolute">
    <NLayoutSider
      :collapsed="siderCollapsed"
      :bordered="true"
      :show-trigger="true"
      collapse-mode="width"
      :collapsed-width="64"
      :width="260"
      :native-scrollbar="false"
      content-style="height: 100%; display: flex; flex-direction: column; justify-content: space-between"
      class="z-2"
      @collapse="siderCollapsed = true"
      @expand="siderCollapsed = false"
    >
      <div>
        <Account :collapsed="siderCollapsed" />
        <SidebarMenu />
      </div>
      <div class="flex flex-col p-2">
        <TimeTrackerTaskQuickStartPanel v-show="!siderCollapsed" />
        <ClientOnly><TimeTracker :collapsed="siderCollapsed" /></ClientOnly>
      </div>
    </NLayoutSider>
    <slot />
  </NLayout>
</template>
