<script setup lang="ts">
const { data: tasksData, execute: executeRecentTasksFetch, status } = useApiTaskGetListPaginated({
  perPage: 5,
  withProject: true,
  withTaskList: true,
}, {
  immediate: false,
  watch: false,
})

const recentTasks = useRecentTasks()

const recentTasksName = Symbol('recent-tasks')

const expandedNames = computed(() => {
  return recentTasks.value.expanded ? [recentTasksName.toString()] : []
})

function updateExpandedState(names: string[]) {
  recentTasks.value.expanded = names.includes(recentTasksName.toString())
  if (recentTasks.value.expanded)
    fetchRecentTasks()
}

async function fetchRecentTasks() {
  await executeRecentTasksFetch()
  if (tasksData.value)
    recentTasks.value.tasks = tasksData.value.data
}

if (recentTasks.value.expanded)
  await fetchRecentTasks()
</script>

<template>
  <NCollapse class="mx-4 mb-4" :expanded-names="expandedNames" @update:expanded-names="updateExpandedState">
    <NCollapseItem :name="recentTasksName.toString()">
      <template #header>
        <div class="text-xs text-gray-6">
          Letzte 5 Aufgaben
        </div>
      </template>
      <NSpin :show="status === 'pending'">
        <div class="space-y-2">
          <TimeTrackerTaskQuickStartButton v-for="task in recentTasks.tasks" :key="task.id" :task="task" />
        </div>
      </NSpin>
    </NCollapseItem>
  </NCollapse>
</template>
