<script setup lang="ts">
import type { DropdownOption } from 'naive-ui'
import { Icon } from '#components'
import * as Sentry from '@sentry/nuxt'

defineProps({
  collapsed: {
    type: Boolean,
    default: false,
  },
})

const { user } = useUserStore()

type AccountDropDownKey = 'logout' | 'settings'

const dropDownOptions: Array<DropdownOption & { key: AccountDropDownKey }> = [
  {
    label: 'Einstellungen',
    key: 'settings',
    icon: () => h(Icon, { name: 'i-ic-baseline-settings' }),
  },
  {
    label: 'Logout',
    key: 'logout',
    icon: () => h(Icon, { name: 'i-ic-baseline-logout' }),
  },
]

const { logout } = useAuthStore()

function accountDropdownSelect(key: AccountDropDownKey) {
  switch (key) {
    case 'logout':
      logout()
      break
    case 'settings':
      navigateTo({ name: 'settings' })
      break
  }
}

async function openSentry() {
  const feedback = Sentry.feedbackIntegration({ autoInject: false })
  const form = await feedback.createForm()
  form.appendToDom() // Fügt das Formular zum DOM hinzu
  form.open() // Öffnet das Formular
}
</script>

<template>
  <aside
    class="my-4 w-full flex items-center pl-7 pr-2 space-x-1.5"
    :class="{ '!px-4': collapsed }"
  >
    <NDropdown
      trigger="click"
      :options="dropDownOptions"
      @select="accountDropdownSelect"
    >
      <NButton circle quaternary>
        <template #icon>
          <NAvatar size="small" src="/placeholder.png" round class="shrink-0">
            <template #fallback>
              <div class="w-full flex items-center justify-center">
                {{ user?.employee.forename[0] }}{{ user?.employee.surname[0] }}
              </div>
            </template>
          </NAvatar>
        </template>
      </NButton>
    </NDropdown>
    <div v-show="!collapsed" class="grow">
      {{ user?.employee.forename }} {{ user?.employee.surname }}
    </div>
    <NTooltip>
      <template #trigger>
        <NButton v-show="!collapsed" quaternary @click="logout">
          <template #icon>
            <Icon name="i-ic-baseline-logout" />
          </template>
        </NButton>
      </template>
      Abmelden
    </NTooltip>
    <NTooltip>
      <template #trigger>
        <NButton v-show="!collapsed" quaternary @click="openSentry">
          <template #icon>
            <Icon name="i-fa6-solid-bullhorn" />
          </template>
        </NButton>
      </template>
      Report an issue
    </NTooltip>
  </aside>
</template>
